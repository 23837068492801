import React from 'react';
import { MediumReview, SpotCaution, SpotWave } from '@balance-web/illustration';
import { Text } from '@balance-web/text';
import { PlusIcon } from '@balance-web/icon';

import type { Meta, Story } from '@reckon-web/storybook';

import type { EmptyStateProps } from './EmptyState';
import { EmptyState } from './EmptyState';

export default {
  title: 'Timesheets/Common/EmptyState',
  component: EmptyState,
} as Meta;

export const Complete: Story<EmptyStateProps> = (args) => {
  return (
    <EmptyState
      image={<MediumReview />}
      actions={[
        {
          action: () => {},
          label: 'Do stuff',
        },
        {
          action: () => {},
          label: 'Do something else',
        },
      ]}
      {...args}
    />
  );
};
Complete.args = {
  title: 'Example Empty State',
};

export const EmptyGuidance: Story<EmptyStateProps> = (args) => {
  return (
    <EmptyState
      image={<SpotWave />}
      fullScreen={true}
      content={
        <Text weight="medium" color="muted" size="small">
          Guidance instructions, foo bar baz, foo bar baz
        </Text>
      }
      actions={[
        {
          action: () => {},
          label: 'Do stuff',
        },
      ]}
      {...args}
    />
  );
};
EmptyGuidance.args = {
  title: 'Example EMPTY state guidance',
};

export const ErrorGuidance: Story<EmptyStateProps> = (args) => {
  return (
    <EmptyState
      image={<SpotCaution />}
      fullScreen={true}
      content={
        <Text weight="medium" color="muted" size="small">
          Guidance instructions, foo bar baz, foo bar baz
        </Text>
      }
      actions={[
        {
          action: () => {},
          label: 'Do stuff',
        },
      ]}
      {...args}
    />
  );
};
ErrorGuidance.args = {
  title: 'Example ERROR state guidance',
};

export const SubtleButtonWeight: Story<EmptyStateProps> = (args) => {
  return (
    <EmptyState
      image={<SpotWave />}
      fullScreen={true}
      content={
        <Text weight="medium" color="muted" size="small">
          Guidance instructions, foo bar baz, foo bar baz
        </Text>
      }
      actions={[
        {
          action: () => {},
          label: 'Do stuff',
        },
      ]}
      {...args}
    />
  );
};
SubtleButtonWeight.args = {
  title: 'Example EMPTY state guidance',
};

export const LongInstructions: Story<EmptyStateProps> = (args) => {
  return (
    <EmptyState
      image={<SpotCaution />}
      fullScreen={true}
      content={
        <Text weight="medium" color="muted" size="small">
          Guidance instructions, foo bar baz, foo bar baz,Guidance instructions,
          foo bar baz, foo bar baz
        </Text>
      }
      actions={[
        {
          action: () => {},
          label: 'Do stuff',
        },
      ]}
      {...args}
    />
  );
};
LongInstructions.args = {
  title: 'Example long instructions',
};

export const WithIconBefore: Story<EmptyStateProps> = (args) => {
  return (
    <EmptyState
      image={<SpotWave />}
      fullScreen={true}
      content={
        <Text weight="medium" color="muted" size="small">
          Guidance instructions, foo bar baz
        </Text>
      }
      actions={[
        {
          action: () => {},
          iconBefore: PlusIcon,
          label: 'Do stuff',
        },
      ]}
      {...args}
    />
  );
};
WithIconBefore.args = {
  title: 'Example Icon before',
};

export const NoActions: Story<EmptyStateProps> = (args) => {
  return (
    <EmptyState
      image={<SpotWave />}
      fullScreen={true}
      content={
        <Text weight="medium" color="muted" size="small">
          Guidance instructions, foo bar baz, foo bar baz
        </Text>
      }
      {...args}
    />
  );
};
NoActions.args = {
  title: 'Example EMPTY state guidance',
};
