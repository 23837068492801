/**
 * Do NOT MODIFY THIS FILE OR ANY OTHER FILE IN THE __generated__ DIRECTORY.
 *
 * Generated using StyleDictionary on Fri, 17 Jan 2025 00:40:48 GMT.
 */

export const lightThemeRaw = {
  colors: {
    background: '#ffffff',
    text: '#181A20',
    primary: '#5A3FFF',
    secondary: '#0a004a',
    brand: '#F84184',
    accent: '#DF322A',
    danger: '#C0000C',
    warning: '#FFB027',
    success: '#009754',
    info: '#0091EA',
    decorative: [
      '#BDE7FF',
      '#B1FAE0',
      '#FFF0B3',
      '#FFB7B2',
      '#FFB1D8',
      '#CBC1F4',
      '#AFD5FF',
    ],
    variant: [
      '#BDE7FF',
      '#B1FAE0',
      '#FFF0B3',
      '#FFB7B2',
      '#FFB1D8',
      '#CBC1F4',
      '#AFD5FF',
    ],
  },
  components: {
    chip: {
      borderRadius: 8,
      filled: {
        surfaceColor: '#F9F7FF',
        borderColor: '#DDDEE9',
        iconColor: '#52535C',
        textColor: '#52535C',
        interactions: {
          hover: {
            surfaceColor: '#1B1B1F14',
          },
          pressed: {
            surfaceColor: '#1B1B1F1F',
          },
        },
        active: {
          surfaceColor: '#E1DAFF',
          borderColor: '#E1DAFF',
          iconColor: '#0a004a',
          textColor: '#0a004a',
          interactions: {
            hover: {
              surfaceColor: '#14005D14',
            },
            pressed: {
              surfaceColor: '#14005D1F',
            },
          },
        },
      },
      outline: {
        surfaceColor: '#ffffff',
        borderColor: '#DDDEE9',
        iconColor: '#52535C',
        textColor: '#52535C',
        interactions: {
          hover: {
            surfaceColor: '#1B1B1F14',
          },
          pressed: {
            surfaceColor: '#1B1B1F1F',
          },
        },
        active: {
          surfaceColor: '#E1DAFF',
          borderColor: '#E1DAFF',
          iconColor: '#0a004a',
          textColor: '#0a004a',
          interactions: {
            hover: {
              surfaceColor: '#14005D14',
            },
            pressed: {
              surfaceColor: '#14005D1F',
            },
          },
        },
      },
      small: {
        height: 32,
        paddingInline: 12,
      },
      medium: {
        height: 40,
        paddingInline: 16,
      },
      disabled: {
        surfaceColor: '#5E5E620A',
        textColor: '#BDBFCA',
        iconColor: '#BDBFCA',
      },
    },
  },
  name: 'Reckon web: Light',
  borderWidth: {
    standard: 1,
    large: 2,
  },
  breakpoints: {
    small: 576,
    medium: 768,
    large: 882,
    xlarge: 1200,
  },
  contentWidth: {
    xsmall: 480,
    small: 720,
    medium: 1024,
    large: 1200,
    xlarge: 1440,
  },
  elevation: {
    card: 100,
    dropdown: 200,
    sticky: 300,
    modal: 400,
    popover: 500,
    toast: 600,
  },
  shadow: {
    xsmall: '0px 1px 2px rgba(0, 0, 0, 0.2)',
    small: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    medium: '0px 2px 8px rgba(0, 0, 0, 0.2)',
    large: '0px 4px 16px rgba(0, 0, 0, 0.2)',
    xlarge: '-8px 8px 32px rgba(0, 0, 0, 0.2)',
  },
  palette: {
    decorative: [
      '#FFCA3A',
      '#12714D',
      '#106F85',
      '#1D6FEA',
      '#8246D4',
      '#C31F83',
      '#B94018',
    ],
    variant: [
      '#BDE7FF',
      '#B1FAE0',
      '#FFF0B3',
      '#FFB7B2',
      '#FFB1D8',
      '#CBC1F4',
      '#AFD5FF',
    ],
    actions: {
      passive: '#585c62',
      active: '#5A3FFF',
      critical: '#C0000C',
    },
    global: {
      accent: '#DF322A',
      border: '#e4e5e6',
      focusRing: 'rgba(0,122,255,0.2)',
      loading: '#5A3FFF',
    },
    border: {
      standard: '#e4e5e6',
      muted: '#f2f2f2',
      accent: '#d0cee5',
      active: '#c2dfff',
      cautious: '#ffeccb',
      critical: '#f8c3c6',
      informative: '#c2e5fa',
      positive: '#c2e6d6',
    },
    background: {
      base: '#ffffff',
      muted: '#f8f8f9',
      dim: '#f2f2f2',
      shade: '#ebebec',
      dialog: '#FCFBFF',
      accent: '#e0deed',
      selectableHover: '#fbfbfb',
      selectablePressed: '#f6f6f7',
      selectableSelected: '#f5faff',
      selectableSelectedHover: '#ebf4ff',
      active: '#d6eaff',
      cautious: '#fff2dc',
      critical: '#fbd7d9',
      informative: '#d6edfc',
      positive: '#d6eee4',
      activeMuted: '#cce4ff',
      cautiousMuted: '#ffefd4',
      criticalMuted: '#f9cdd0',
      informativeMuted: '#cce9fb',
      positiveMuted: '#cceadd',
    },
    text: {
      base: '#181A20',
      muted: '#585c62',
      dim: '#909396',
      link: '#5A3FFF',
      linkHover: '#3689ff',
      linkVisited: '#3C3391',
      accent: '#3C3391',
      active: '#5A3FFF',
      cautious: '#B35400',
      critical: '#c0000c',
      informative: '#0091EA',
      positive: '#009754',
    },
    heading: {
      base: '#0a004a',
      muted: '#585c62',
      dim: '#909396',
      accent: '#3C3391',
      active: '#5A3FFF',
      cautious: '#B35400',
      critical: '#E30613',
      informative: '#0091EA',
      positive: '#009754',
    },
    formInput: {
      background: '#ffffff',
      backgroundHovered: '#f8f8f9',
      backgroundFocused: '#ffffff',
      backgroundDisabled: '#f8f8f9',
      backgroundInvalid: '#fbd7d9',
      border: '#e4e5e6',
      borderHovered: '#e4e5e6',
      borderFocused: '#5A3FFF',
      borderDisabled: '#e4e5e6',
      borderInvalid: '#c0000c',
      text: '#181A20',
      textDisabled: '#C9CBCD',
      textPlaceholder: '#C9CBCD',
      textInvalid: '#E30613',
    },
    formControl: {
      background: '#ffffff',
      backgroundDisabled: '#e4e5e6',
      border: '#e4e5e6',
      interaction: '#DF322A',
      foregroundChecked: '#ffffff',
      foregroundDisabled: '#909396',
    },
    segmentedControl: {
      track: '#f2f2f2',
      divider: 'rgba(32,38,45,0.16)',
      backgroundSelected: '#ffffff',
      text: '#585c62',
      textFocused: '#181A20',
      textPressed: '#181A20',
      textSelected: '#181A20',
    },
    toggle: {
      track: '#e4e5e6',
      trackChecked: '#007AFF',
      trackDisabled: '#f2f2f2',
      trackDisabledChecked: '#909396',
      handle: 'white',
    },
    listItem: {
      backgroundFocused: '#edeeee',
      backgroundPressed: '#e4e5e6',
      backgroundSelected: '#e4e5e6',
      divider: '#e4e5e6',
      text: '#181A20',
      textFocused: '#181A20',
      textPressed: '#181A20',
      textSelected: '#181A20',
    },
    table: {
      rowBackgroundCautious: '#fffaf2',
      rowBackgroundCritical: '#fdf0f1',
      rowBackgroundPositive: '#f0f9f5',
      rowBackgroundDisabled: '#E0E0E0',
      rowDropBackgroundValid: '#AAD5FF',
      rowDropBackgroundInvalid: '#f9cdd0',
      cellBorderDisabled: '#939393',
    },
    menuItem: {
      backgroundFocused: '#f2f2f2',
      backgroundPressed: '#e4e5e6',
      backgroundSelected: '#f0f7ff',
      divider: '#e4e5e6',
      text: '#181A20',
      textDisabled: '#909396',
      textFocused: '#181A20',
      textPressed: '#181A20',
      textSelected: '#181A20',
    },
    tooltip: {
      background: '#585c62',
      text: '#ffffff',
    },
    button: {
      active: {
        background: '#5A3FFF',
        backgroundFocused: '#6e56ff',
        backgroundPressed: '#6e56ff',
      },
      critical: {
        background: '#C0000C',
        backgroundFocused: '#e2423a',
        backgroundPressed: '#e34b44',
      },
      passive: {
        background: '#585c62',
        backgroundFocused: '#5f6369',
        backgroundPressed: '#4e5258',
      },
    },
    actionButton: {
      background: '#ffffff',
      backgroundFocused: '#f2f2f2',
      backgroundPressed: '#e4e5e6',
      backgroundSelected: '#e4e5e6',
      border: '#e4e5e6',
      borderFocused: '#dbdcdd',
      borderPressed: '#c9cbcd',
      borderSelected: '#c9cbcd',
      text: '#585c62',
      textFocused: '#181A20',
      textPressed: '#181A20',
      textSelected: '#181A20',
    },
    interactive: {
      base: {
        background: '#ffffff',
        hover: '#f2f2f2',
        active: '#e4e5e6',
      },
    },
  },
  primitives: {
    colour: {
      primary: {
        k0: '#000000',
        k10: '#0a004a',
        k20: '#210098',
        k30: '#3000dd',
        k40: '#5a3fff',
        k50: '#6652ff',
        k60: '#8274ff',
        k70: '#a096ff',
        k80: '#c0b7ff',
        k90: '#e1daff',
        k95: '#f2ebff',
        k99: '#fffaff',
        k100: '#ffffff',
        interactions: {
          default: {
            light: '#ffffff14',
            mid: '#ffffff1f',
            high: '#ffffff29',
          },
          container: {
            light: '#14005d14',
            mid: '#14005d1f',
            high: '#14005d29',
          },
          inverse: {
            light: '#5a3fff14',
            mid: '#5a3fff1f',
            high: '#5a3fff29',
          },
        },
      },
      secondary: {
        k0: '#000000',
        k10: '#370e0c',
        k20: '#6f1a16',
        k30: '#a72620',
        k40: '#df322a',
        k50: '#e6544b',
        k60: '#ec746c',
        k70: '#f2948d',
        k80: '#f8b4ae',
        k90: '#fed4cf',
        k95: '#ffeae6',
        k99: '#fcfbfc',
        k100: '#ffffff',
        interactions: {
          default: {
            light: '#ffffff14',
            mid: '#ffffff1f',
            high: '#ffffff29',
          },
          container: {
            light: '#370e0c14',
            mid: '#370e0c1f',
            high: '#370e0c29',
          },
          inverse: {
            light: '#df322a14',
            mid: '#df322a1f',
            high: '#df322a29',
          },
        },
      },
      neutral: {
        k0: '#000000',
        k10: '#181a20',
        k20: '#292a31',
        k25: '#32343b',
        k30: '#3c3d46',
        k35: '#464850',
        k40: '#52535c',
        k50: '#6a6c75',
        k60: '#84858f',
        k70: '#a0a1ab',
        k80: '#bdbfca',
        k90: '#dddee9',
        k95: '#eeeef9',
        k98: '#f9f7ff',
        k99: '#fcfbff',
        k100: '#ffffff',
        interactions: {
          default: {
            light: '#ffffff14',
            mid: '#ffffff1f',
            high: '#ffffff29',
          },
          container: {
            light: '#1b1b1f14',
            mid: '#1b1b1f1f',
            high: '#1b1b1f29',
          },
          inverse: {
            light: '#5e5e6214',
            mid: '#5e5e621f',
            high: '#5e5e6229',
          },
        },
      },
      reserved: {
        critical: {
          k0: '#000000',
          k10: '#410001',
          k20: '#690003',
          k30: '#930007',
          k40: '#c0000c',
          k50: '#ec1419',
          k60: '#ff5447',
          k70: '#ff8a7c',
          k80: '#ffb4aa',
          k90: '#ffdad5',
          k95: '#ffedea',
          k99: '#fffbff',
          k100: '#ffffff',
          interactions: {
            default: {
              light: '#ffffff14',
              mid: '#ffffff1f',
              high: '#ffffff29',
            },
            container: {
              light: '#41000114',
              mid: '#4100011f',
              high: '#41000129',
            },
            inverse: {
              light: '#c0000c14',
              mid: '#c0000c1f',
              high: '#c0000c29',
            },
          },
        },
        info: {
          k0: '#000000',
          k10: '#001d35',
          k20: '#003256',
          k30: '#00497a',
          k40: '#0062a0',
          k50: '#007bc8',
          k60: '#1596ef',
          k70: '#5db0ff',
          k80: '#9ccaff',
          k90: '#d0e4ff',
          k95: '#e9f1ff',
          k99: '#fdfcff',
          k100: '#ffffff',
          interactions: {
            default: {
              light: '#ffffff14',
              mid: '#ffffff1f',
              high: '#ffffff29',
            },
            container: {
              light: '#001d3514',
              mid: '#001d351f',
              high: '#001d3529',
            },
            inverse: {
              light: '#0062a014',
              mid: '#0062a01f',
              high: '#0062a029',
            },
          },
        },
        warning: {
          k0: '#000000',
          k10: '#2a1800',
          k20: '#462b00',
          k30: '#633f00',
          k40: '#835500',
          k50: '#a46b00',
          k60: '#c68200',
          k70: '#ea9b00',
          k80: '#ffb94b',
          k90: '#ffddb0',
          k95: '#ffeed9',
          k99: '#fcfcfc',
          k100: '#ffffff',
          interactions: {
            light: {
              default: {
                light: '#ffffff14',
                mid: '#ffffff1f',
                high: '#ffffff29',
              },
              container: {
                light: '#2a180014',
                mid: '#2a18001f',
                high: '#2a180029',
              },
              inverse: {
                light: '#83550014',
                mid: '#8355001f',
                high: '#83550029',
              },
            },
          },
        },
        success: {
          k0: '#000000',
          k10: '#00210e',
          k20: '#00391c',
          k30: '#00522b',
          k40: '#006d3b',
          k50: '#00894c',
          k60: '#23a560',
          k70: '#47c179',
          k80: '#65dd92',
          k90: '#83faac',
          k95: '#c2ffd0',
          k99: '#f5fff3',
          k100: '#ffffff',
          interactions: {
            default: {
              light: '#ffffff14',
              mid: '#ffffff1f',
              high: '#ffffff29',
            },
            container: {
              light: '#00210e14',
              mid: '#00210e1f',
              high: '#00210e29',
            },
            inverse: {
              light: '#006d3b14',
              mid: '#006d3b1f',
              high: '#006d3b29',
            },
          },
        },
      },
      surface: {
        default: {
          k0: '#ffffff',
          k1: '#F9F7FF',
          k2: '#F3F2FA',
          k3: '#EDECF4',
          k4: '#E8E7F1',
        },
        tonal: {
          k1: '#fffcfc',
          k2: '#fff5f6',
          k3: '#ffeceb',
          k4: '#fedede',
        },
      },
      decorative: {
        teal: {
          foreground: '#ffffff',
          surface: '#106f85',
        },
        blue: {
          foreground: '#ffffff',
          surface: '#1d6fea',
        },
        green: {
          foreground: '#ffffff',
          surface: '#12714d',
        },
        yellow: {
          foreground: '#1b1b1f',
          surface: '#ffca3a',
        },
        coral: {
          foreground: '#ffffff',
          surface: '#b94018',
        },
        pink: {
          foreground: '#ffffff',
          surface: '#c31f83',
        },
        purple: {
          foreground: '#ffffff',
          surface: '#8246d4',
        },
      },
      brand: {
        foreground: '#ffffff',
        background: '#ff5447',
      },
    },
  },
  radii: {
    none: 0,
    xsmall: 4,
    small: 6,
    medium: 8,
    large: 12,
    xlarge: 16,
    full: 999,
  },
  sizes: {
    xsmall: {
      fontSizeDisplay: '0.75rem',
      fontSizeText: '0.75rem',
      borderRadius: 4,
      boxSize: 24,
      gap: 4,
      paddingX: 8,
      paddingY: 2,
    },
    small: {
      fontSizeDisplay: '0.75rem',
      fontSizeText: '0.875rem',
      borderRadius: 6,
      boxSize: 32,
      gap: 8,
      paddingX: 12,
      paddingY: 4,
    },
    medium: {
      fontSizeDisplay: '1.125rem',
      fontSizeText: '1rem',
      borderRadius: 8,
      boxSize: 40,
      gap: 8,
      paddingX: 16,
      paddingY: 8,
    },
  },
  sizing: {
    xxsmall: 16,
    xsmall: 24,
    small: 32,
    base: 40,
    medium: 48,
    large: 56,
    xlarge: 72,
  },
  spacing: {
    none: 0,
    xxsmall: 2,
    xsmall: 4,
    small: 8,
    medium: 12,
    large: 16,
    xlarge: 24,
    xxlarge: 32,
  },
  typography: {
    fontFamily: {
      monospace:
        '"SFMono-Medium","SF Mono","Segoe UI Mono","Roboto Mono","Ubuntu Mono", Menlo, Consolas, Courier, monospace',
      body:
        'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      heading:
        'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    },
    fontSize: {
      xsmall: '0.75rem',
      small: '0.875rem',
      medium: '1rem',
      large: '1.125rem',
      xlarge: '1.25rem',
      xxlarge: '1.5rem',
      xxxlarge: '1.875rem',
      xxxxlarge: '2.25rem',
      xxxxxlarge: '3rem',
      xxxxxxlarge: '4rem',
    },
    fontWeight: {
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      heavy: 800,
    },
    leading: {
      tighter: 1,
      tight: 1.2,
      base: 1.4,
      loose: 1.6,
      looser: 1.8,
    },
  },
};
